// Baseline
$color-base: (
  4: var(--color-base-4, #00061A),
  3: var(--color-base-3, #000C35),
  2: var(--color-base-2, #00114F),
  1: var(--color-base-1, #001769),
);

// Accent
$color-accent: (
  3: var(--color-accent-3, linear-gradient(256deg, #FFEEC2 0%, #FFC431 100%)),
  2: var(--color-accent-2, #06EDC4),
  1: var(--color-accent-1, #2F44FE),
);

$color-symbol: (
  4: var(--color-symbol-3, #8338EC),
  3: var(--color-symbol-3, #FF006E),
  2: var(--color-symbol-2, #3A86FF),
  1: var(--color-symbol-1, #06D6A0),
);

$color-neutral: var(--color-neutral, #FFFFFF);
// don't exist on the backend, just here for the elements which are always white
$color-neutral-2: #FFFFFF;

$color-system: (
  400: var(--color-informative, #2388FB),
  300: var(--color-success, #7FB800),
  200: var(--color-warning, #FFBA5A),
  100: var(--color-error, #FF6666),
);
