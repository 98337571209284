@import "reset";
@import "typography";
@import "ui";
@import "buttons";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--primary-font), sans-serif;
}
