@import 'sizes';
@import 'colors';

// desktop
.text-mega-large {
  font-size: map-get($font-sizes, 800);
  font-weight: map-get($font-weights, 900);
  line-height: map-get($line-heights, 100);
}

.text-extra-large {
  font-size: map-get($font-sizes, 700);
  font-weight: map-get($font-weights, 900);
  line-height: map-get($line-heights, 100);
}

.text-large {
  font-size: map-get($font-sizes, 600);
  font-weight: map-get($font-weights, 900);
  line-height: map-get($line-heights, 100);
}

.text-medium {
  font-size: map-get($font-sizes, 500);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 100);
}

.text-small {
  font-size: map-get($font-sizes, 400);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 100);
}

.text-body-0 {
  font-size: map-get($font-sizes, 400);
  font-weight: map-get($font-weights, 500);
}

.text-body-1 {
  font-size: map-get($font-sizes, 400);
  font-weight: map-get($font-weights, 500);
  line-height: map-get($line-heights, 300);
}

.text-body-2 {
  font-size: map-get($font-sizes, 300);
  font-weight: map-get($font-weights, 500);
}

.text-microcopy {
  text-transform: uppercase;
  font-size: map-get($font-sizes, 200);
  font-weight: map-get($font-weights, 700);
}

.text-labels {
  font-size: map-get($font-sizes, 100);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 100);
  text-transform: uppercase;
}

// mobile
.mobile-version {
  .text-mega-large {
    font-size: map-get($font-sizes, 650);
  }

  .text-extra-large {
    font-size: map-get($font-sizes, 650);
  }

  .text-large {
    font-size: map-get($font-sizes, 450);
  }

  .text-medium {
    font-size: map-get($font-sizes, 420);
  }

  .text-small {
    font-size: map-get($font-sizes, 300);
  }

  .text-body-0 {
    font-size: map-get($font-sizes, 300);
  }

  .text-body-1 {
    font-size: map-get($font-sizes, 300);
  }

  .text-body-2 {
    font-size: map-get($font-sizes, 200);
  }

  .text-microcopy {
    font-size: map-get($font-sizes, 100);
  }

  .text-labels {
    font-size: map-get($font-sizes, 50);
  }
}
