@import 'typography';
@import 'colors';
@import 'sizes';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: PrimaryFont, sans-serif;
  border-radius: var(--corner-radius-cta, 28px);
  border: 2px solid transparent;
  text-align: center;
  transition: all 0.1s;
  cursor: pointer;
  height: 56px;
  padding: 0 24px;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12)
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.primary-button {
  @extend .button;
  @extend .text-small;
  background: var(--z-cta-buttons-primary-background-color, map-get($color-accent, 1));
  color: var(--z-cta-buttons-primary-text-color, $color-neutral);
}

.secondary-button {
  @extend .button;
  @extend .text-small;
  background: $color-neutral;
  color: map-get($color-accent, 1);
}

.primary-button-outline {
  @extend .button;
  @extend .text-small;
  background: transparent;
  border-color: var(--z-cta-buttons-outline-background-color, map-get($color-accent, 1));
  color: var(--z-cta-buttons-outline-text-color, $color-neutral);
}

.secondary-button-outline {
  @extend .button;
  @extend .text-small;
  background: transparent;
  border-color: $color-neutral;
  color: $color-neutral;
}

.primary-button:disabled,
.secondary-button:disabled,
.primary-button-outline:disabled,
.secondary-button-outline:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background: var(--z-cta-buttons-disabled-background-color, map-get($color-accent, 1));
  color: var(--z-cta-buttons-disabled-text-color, $color-neutral);
}

.transparent-btn {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.wide-button {
  width: 280px;
  margin: 0 auto;
}

.mobile-version {
  .button {
    padding: map-get($spaces, 300) map-get($spaces, 700);
  }

  .wide-button {
    width: 240px;
  }
}


