@import "colors";

.invisible-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

// Layout styles
.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}
